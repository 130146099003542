<template>
	<div class="d-flex flex-row">
		<v-treeview
			:items="folders"
			item-key="itemId"
			item-text="text"
			item-children="children"
	
			:open.sync="openNodeIds"
			activatable
		>
			<!-- FOLDER LABEL -->
			<template v-slot:label="{ item }">
				<span style="cursor: pointer" @click="folderActivation(item)">{{
					item.text
				}}</span>
				<v-progress-linear
					indeterminate
					height="2"
					v-if="folderIsLoading && activeNodeIds.includes(item.itemId)"
				></v-progress-linear>
			</template>
		</v-treeview>
		<!-- <div>folders:{{folders}}</div> -->
		<div>openNodeIds:{{JSON.stringify(openNodeIds)}}</div>
		<div>activeNodeIds:{{JSON.stringify(activeNodeIds)}}</div>
		<!-- <v-btn @click="test">TEST</v-btn> -->
	</div>
</template>

<script>
	import { mapActions } from "vuex";
	import FolderService, { VcadFolder } from "../services/folderService";
	export default {
		props: {
			/**
			 * Currently selected project. Used for the initial tree load.
			 */
			selectedProject: {
				type: Object,
				required: true,
			},
			/**
			 * Disables tree item selection. Used to prevent overloading and conflicts of tree.
			 */
			disableClick: {
				type: Boolean,
				required: false,
				default: false,
			},
		},
		data: () => ({
			folders: [],
			openNodeIds: [],
			activeNodeIds: [],

			// optional
			projectIsLoading: false,
			folderIsLoading: false,
		}),
		watch:{
			activeNodeIds(newVal, oldVal){
				console.log(`newVal: ${newVal}`);
				console.log(`oldVal: ${oldVal}`);
			}
		},
		methods: {
			...mapActions(["fetchNodesNew"]),
			setFolderTree(newFolderTree) {
				this.folders = newFolderTree;
			},
			async getProjectRootFolders(projectData) {
				// this.projectIsLoading = true;
				try {
					const nodes = await this.fetchNodesNew({ item: projectData });
					return nodes.map(nodeInfo => VcadFolder.parseAccFolder(nodeInfo));
				} catch (error) {
					// @TODO: Implement error management
					console.log(`ERROR: Calle exited with error.\n${error}`);
				}
				// this.fetchNodesNew({ item: projectData }).then((nodes) => {
				// 	this.folders = nodes.map((node) => ({
				// 		id: node.id,
				// 		itemId: node.itemId || node.id.split('/').at(-1),
				// 		text: node.text,
				// 		type: node.type,
				// 		projectId: node.projectId,
				// 		children: node.children || [],
				// 	}));
					// this.projectIsLoading = false;
				// });
			},
			async loadSubFolder(folderItem) {
				if (!folderItem) {
					return;
				}
				try {
					let newChildren = await this.fetchNodesNew({
						item: folderItem,
					});
					newChildren = newChildren
						.filter((node) => node.type == "folders")
						.map((node) => ({
							id: node.id,
							itemId: node.itemId || node.id.split('/').at(-1),
							text: node.text,
							type: node.type,
							projectId: node.projectId,
							children: node.children || [],
						}));
					folderItem.children = newChildren;
				} catch (error) {
					// @TODO: Implement error management
					console.log(`ERROR: Calle exited with error.\n${error}`);
				}
			},
			/**
			 * Add folder to the "active" prop of treeview and load children.
			 * @param {Object} folder The folder that has been clicked.
			 */
			async folderActivation(folder) {
				// Skip update if click is disabled
				if (this.disableClick || this.folderIsLoading) {
					return;
				}

				const folderId = folder.itemId//folder.id
				// // Set active
				this.activeNodeIds = [folderId];

				// Load children if necessary
				const hasChildren = folder.children && folder.children.length > 0;
				if (!hasChildren) {
					this.folderIsLoading = true;
					await this.loadSubFolder(folder);
					this.folderIsLoading = false;
				}

				// Toggle node open
				const folderOpenIndex = this.openNodeIds.indexOf(folderId);
				if (folderOpenIndex == -1) {
					this.openNodeIds = [...this.openNodeIds, folderId];
				} else {
					this.openNodeIds.splice(folderOpenIndex, 1);
				}
			},
			async getOpenFolders(projectId, folderId){
				const folderService = new FolderService();
				const folders = await folderService.getItemPathFolders(projectId, folderId);
				return folders;
			},
			// async test(){
			// 	const folderService = new FolderService();
			// 	const folders = await folderService.test();
			// 	console.log(folders)
			// }
		},
		async mounted() {
			console.log(JSON.stringify(this.selectedProject))
			const folderItemId = "urn:adsk.wipprod:fs.folder:co.LTa4bzSFRCiKAcT2xQbLRw";
			// load folders
			this.projectIsLoading = true;
			if(folderItemId){
				const folderService = new FolderService();
				const openFolders = await this.getOpenFolders(this.selectedProject.itemId, folderItemId);

				// Set folder List
				this.folders = folderService.folderListToTree(openFolders);

				// Set open folders
				this.openNodeIds = openFolders.map(folder => folder.itemId);

				// Set active folders
				this.activeNodeIds = ["urn:adsk.wipprod:fs.folder:co.BJ25-p8zQoyOneHWuWFxtg"]//[folderItemId];
			}else{
				this.folders = await this.getProjectRootFolders(this.selectedProject);
			}
			this.projectIsLoading = false;

			/**
			 * Sample data - (Nick's Files):
			 * 	projectId: b.89903d26-7ab5-4220-89f5-8d387960f7ac
			 * 	folderId: urn:adsk.wipprod:fs.folder:co.LTa4bzSFRCiKAcT2xQbLRw
			 */

			// ["urn:adsk.wipprod:fs.folder:co.BJ25-p8zQoyOneHWuWFxtg"]
			// ["urn:adsk.wipprod:fs.folder:co.LTa4bzSFRCiKAcT2xQbLRw"]
		},
	};
</script>

<style lang="scss" scoped>
</style>